import * as React from 'react';
import { object, ref, string } from 'yup';
import { updateUsersPassword } from 'api/userAuthentication';
import { Button } from 'styleguide/components';
import FormWrapper from '../FormWrapper/FormWrapper';
import { H4 } from 'styleguide/components/Heading';
import { getQueryStringParams } from 'utils/url';
import PageLevelError from 'styleguide/components/PageLevelError/PageLevelError';
import UserContext from 'contexts/UserContextContainer/UserContext';
import { Form, Formik, FormikValues, FormikErrors } from 'formik';
import Loader from 'styleguide/components/Loader/Loader';
import { useLocation } from 'react-router';
import { formikStatus, updateFormikStatus } from 'libs/utils/updateFormik';
import { Status } from 'libs/utils/api/types';
import mapValues from 'lodash-es/valuesIn';
import { FormikFieldWrapper } from 'app/styleguide/components/Formik';

const ResetPasswordFormSchema = object().shape({
  password: string()
    .ensure()
    .test('password-test', 'Password must be at least 8 characters', value =>
      value === '' ? true : value.length >= 8,
    ),
  confirmation: string()
    .ensure()
    .when('password', {
      is: value => value === '',
      then: schema => schema.oneOf([''], 'Your password and confirmation do not match.'),
      otherwise: schema => schema.oneOf([ref('password')], 'Your password and confirmation do not match.'),
    }),
});

const EditPassword = () => {
  const location = useLocation();
  const userContext = React.useContext(UserContext);
  const initialValues = { password: '', confirmation: '' };

  const updatePassword = (
    values: FormikValues,
    setStatus: (status?: formikStatus) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
  ) => {
    updateUsersPassword({
      password: values.password,
      reset_password_token: getQueryStringParams(location.search).reset_password_token,
    }).then(res => {
      updateFormikStatus(res, setStatus, setSubmitting, setErrors);
      if (res.status === Status.Ok) {
        userContext.logIn(res.payload.user);
      }
    });
  };

  return (
    <FormWrapper>
      <H4 className="-sm:mt-4' mb-4 !text-center !font-hvBold !text-4xl !font-bold">Update password</H4>
      <Formik
        initialValues={initialValues}
        onSubmit={(formikValues, { setStatus, setSubmitting, setErrors }) => {
          updatePassword(formikValues, setStatus, setSubmitting, setErrors);
        }}
        validationSchema={ResetPasswordFormSchema}
      >
        {formikProps => (
          <>
            {formikProps.status && (
              <>
                <br />
                <PageLevelError message={formikProps.status.title}>
                  {!!formikProps.status.errors &&
                    mapValues(formikProps.status.errors).map((value, index) => (
                      <PageLevelError.Item key={index}>{value}</PageLevelError.Item>
                    ))}
                </PageLevelError>
                <br />
              </>
            )}
            <Form>
              <FormikFieldWrapper
                data-cy="password"
                type="password"
                name="password"
                componentType="input"
                labelComponent="New Password"
                inPlaceError
              />
              <FormikFieldWrapper
                data-cy="passwordConfirmation"
                type="password"
                name="confirmation"
                componentType="input"
                labelComponent="Confirm new password"
              />
              {formikProps.isSubmitting ? (
                <Loader />
              ) : (
                <Button
                  dataCy="submitFormBtn"
                  className="mt-6 w-[360px] !px-0 -sm:w-[320px]"
                  type="button"
                  onClick={() => formikProps.submitForm()}
                  color="blue"
                >
                  Change Password
                </Button>
              )}
            </Form>
          </>
        )}
      </Formik>
    </FormWrapper>
  );
};
export default EditPassword;
